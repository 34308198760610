<template>
  <flushing-template :flushes="flushes" :field-errors="fieldErrors" @field-change="onFieldChange" />
</template>

<script>
import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import FlushingTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/flushing/FlushingTemplate';
import { no } from '@/modules/questionnaire/api/helpers';

const { field, requiredField } = fieldBuilder;

const fieldsToResetOnNo = [
  'hotFlushes',
  'flushesDuration',
  'flushLocations',
  'inflammationDiscomfort'
];

const fieldsToReset = ['facialSwelling'];

const FIELDS = [
  ...fieldsToResetOnNo.map(field),
  ...fieldsToReset.map(field),
  requiredField('flushes')
];

export default {
  name: 'Flushing',
  components: {
    FlushingTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    flushes(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToResetOnNo);
      }

      this.resetStoreFieldsByName(fieldsToReset);

      this.showNextStep();
    }
  }
};
</script>
